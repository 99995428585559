import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/ranking",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/views/pages/Profile")
        },
        {
          path: "/ranking",
          name: "ranking",
          component: () => import("@/views/pages/ranking/Ranking.vue")
        },
        {
          path: "/challenges",
          name: "challenges",
          component: () => import("@/views/pages/challenges/Challenges.vue")
        },
        {
          path: "/regulation",
          name: "regulation",
          component: () => import("@/views/pages/regulation/Regulation.vue")
        },
        {
          path: "/challenges/:id",
          name: "challenge",
          component: () => import("@/views/pages/challenges/Challenges.vue")
        },
        {
          path: "users",
          name: "users",
          component: () => import("@/views/pages/admin/Users.vue")
        },
        {
          path: "/blank",
          name: "blank",
          component: () => import("@/views/pages/Blank.vue")
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register")
        }
      ]
    }
  ]
});
